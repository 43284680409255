// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `angular-cli.json`.

export const environment = {
  production: false,
  firebase : {
    apiKey: "AIzaSyDtS-B1EIhX1iuTSSFmTwms5tKVMcHLerc",
    authDomain: "iamcustomized-f8e41.firebaseapp.com",
    databaseURL: "https://iamcustomized-f8e41-default-rtdb.firebaseio.com/",
    projectId: "iamcustomized-f8e41",
    storageBucket: "iamcustomized-f8e41.appspot.com",
    messagingSenderId: "323047386417",
    appId: "1:323047386417:web:fdfd76477b0016e9585410",
    measurementId: "G-P1V4N10TWR"
  }
};